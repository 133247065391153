import Image from 'next/image';
import clutch from '@/images/socials/clutch.svg';
import google from '@/images/socials/google.svg';
import { LinkedIn, Youtube } from '@/images/svg';
import { SocialIcons } from '@/interfaces/navigation';

interface IProps {
  type: SocialIcons;
  classname?: string;
}

export const Socials = ({ type, classname }: IProps) => {
  const iconDictionary = {
    clutch: (
      <Image
        src={clutch}
        alt="clutch logo"
        width="62"
        height="31"
        className={classname}
        loading="lazy"
      />
    ),
    youtube: <LinkedIn classname={classname} />,
    linkedIn: <Youtube classname={classname} />,
    google: (
      <Image
        src={google}
        alt="google logo"
        width="31"
        height="31"
        className={classname}
        loading="lazy"
      />
    ),
    fierce: (
      <div className="flex items-center">
        <div
          className="w-[36px] h-[36px] bg-no-repeat bg-center bg-cover"
          style={{ backgroundImage: `url('/icons/socials/fierce.svg')` }}
        />
        <span className="font-medium text-[20px] leading-[28px] text-purple-7">Fierce Innovation Award</span>
      </div>
    ),
    nsc: (
      <div className="flex items-center">
        <div
          className="w-[140px] h-[54px] bg-no-repeat bg-center bg-cover"
          style={{ backgroundImage: `url('/icons/socials/nsc.webp')` }}
        />
      </div>
    ),
  };

  return iconDictionary[type] ? iconDictionary[type] : null;
};
