/* eslint-disable  max-len */

export const Logo = ({ ...rest }: any) => {
  return (
    <svg
      width="113"
      height="32"
      viewBox="0 0 113 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.38408 3.92041V15.2893L0 19.5527V8.1839L7.38408 3.92041Z"
        fill="#2BBD57"
      />
      <path
        d="M17.2304 20.9744L9.84637 25.2379L0 19.5528L7.38408 15.2893L17.2304 20.9744Z"
        fill="#56E881"
      />
      <path
        d="M14.7695 11.0257L22.1536 6.76221L32 12.4473L24.6145 16.7108L14.7695 11.0257Z"
        fill="#C93D94"
      />
      <path
        d="M24.6145 28.0796L32 23.8161V12.4473L24.6145 16.7108V28.0796Z"
        fill="#A7337B"
      />
      <path
        d="M44.0312 21.1657C43.0452 21.1657 42.1782 20.9976 41.4288 20.6628C40.6795 20.3281 40.1248 19.828 39.762 19.1641C39.4007 18.5002 39.2984 17.6948 39.4567 16.748H42.611C42.5325 17.143 42.5647 17.4847 42.709 17.7733C42.8533 18.0632 43.0732 18.2859 43.3701 18.4442C43.6657 18.6025 44.0172 18.6809 44.4248 18.6809C44.8324 18.6809 45.191 18.6123 45.4991 18.4736C45.8073 18.3349 46.0482 18.1416 46.2191 17.8923C46.3899 17.643 46.488 17.3405 46.5146 16.9847C46.5146 16.748 46.4656 16.5351 46.3661 16.3432C46.2681 16.1527 46.1224 15.9818 45.9319 15.8306C45.7414 15.6793 45.5145 15.542 45.2512 15.416C44.9879 15.2913 44.705 15.1792 44.4038 15.0812C44.1013 14.9832 43.7931 14.8683 43.4766 14.7366C42.5437 14.4215 41.8308 13.9803 41.3378 13.4158C40.8447 12.8513 40.6304 12.1412 40.6963 11.2868C40.7229 10.4058 40.982 9.63403 41.475 8.97012C41.9681 8.30621 42.6222 7.79356 43.4374 7.43219C44.2525 7.07082 45.1728 6.89014 46.1981 6.89014C47.1309 6.89014 47.9293 7.04421 48.5932 7.35376C49.2571 7.6633 49.7529 8.12272 50.0821 8.73341C50.4098 9.34409 50.5219 10.0836 50.4169 10.9521H47.243C47.2822 10.6369 47.2458 10.3568 47.1351 10.1145C47.023 9.87074 46.8424 9.68726 46.593 9.5626C46.3437 9.43794 46.0412 9.37491 45.6854 9.37491C45.3296 9.37491 45.0383 9.43094 44.768 9.54299C44.4991 9.65504 44.2848 9.82592 44.1265 10.0556C43.9682 10.2853 43.8828 10.5515 43.8702 10.854C43.8435 11.0781 43.8772 11.2714 43.9682 11.4353C44.0606 11.5992 44.1811 11.7448 44.3324 11.8695C44.4836 11.9941 44.6713 12.1132 44.894 12.2239C45.1167 12.3359 45.3675 12.441 45.6434 12.539C45.9193 12.637 46.2023 12.7393 46.4908 12.8443C46.9502 13.0152 47.3774 13.2085 47.7724 13.4256C48.1674 13.6427 48.5148 13.9018 48.8173 14.2044C49.1198 14.5069 49.3523 14.8613 49.5176 15.2689C49.6815 15.6765 49.7445 16.1359 49.7053 16.6485C49.6661 17.5422 49.4028 18.3279 48.9167 19.0045C48.4307 19.681 47.7668 20.2104 46.925 20.5914C46.0832 20.9724 45.1181 21.1629 44.027 21.1629L44.0312 21.1657Z"
        fill="white"
      />
      <path
        d="M51.3119 25.2667L54.1972 20.2636L53.4577 20.258L51.2684 10.9912H54.4619L55.8598 17.381L59.5281 10.9912H62.6824L54.4255 25.2667H51.3105H51.3119Z"
        fill="white"
      />
      <path
        d="M68.1128 21.1656C67.6394 21.1656 67.2192 21.1067 66.8508 20.9877C66.4824 20.87 66.1673 20.6991 65.904 20.475C65.6406 20.2523 65.4305 20.0156 65.2737 19.7649L64.7218 20.9289H62.1194L64.6238 6.73315H67.582L66.6155 12.1551C67.0105 11.7475 67.4769 11.4128 68.0162 11.1494C68.5554 10.8861 69.1731 10.7545 69.8692 10.7545C70.6844 10.7545 71.3904 10.9547 71.9884 11.3553C72.5865 11.7559 73.0431 12.2994 73.3583 12.9815C73.6734 13.665 73.8121 14.441 73.7729 15.308C73.7337 16.0966 73.5656 16.8487 73.27 17.5659C72.9745 18.283 72.5697 18.9105 72.0571 19.4484C71.5444 19.9876 70.9534 20.4078 70.2824 20.7103C69.6115 21.0129 68.8888 21.1642 68.1128 21.1642V21.1656ZM67.8761 18.5827C68.3887 18.5827 68.8552 18.4581 69.2754 18.2088C69.6956 17.9595 70.0345 17.6177 70.2908 17.1835C70.5472 16.7493 70.6886 16.2506 70.7152 15.6848C70.7545 15.2506 70.6914 14.8542 70.5276 14.4914C70.3637 14.13 70.12 13.8471 69.7978 13.644C69.4757 13.4409 69.0905 13.3387 68.6437 13.3387C68.1184 13.3387 67.6478 13.4675 67.2346 13.7239C66.8214 13.9802 66.4824 14.3289 66.2191 14.7687C65.9558 15.21 65.8185 15.7128 65.8045 16.2773C65.7779 16.7381 65.8465 17.1415 66.0118 17.4902C66.1757 17.839 66.4166 18.1079 66.7318 18.2984C67.0469 18.4889 67.4279 18.5841 67.8761 18.5841V18.5827Z"
        fill="white"
      />
      <path
        d="M84.1251 12.8148C83.803 12.1775 83.3198 11.6746 82.6755 11.3063C82.0311 10.9379 81.2566 10.7544 80.349 10.7544C79.4413 10.7544 78.6401 10.9085 77.9034 11.218C77.1667 11.5276 76.5265 11.9548 75.9817 12.4996C75.4354 13.0459 75.0054 13.6762 74.6903 14.3919C74.3751 15.109 74.1973 15.8808 74.158 16.7086C74.1188 17.5896 74.2701 18.3684 74.6118 19.0449C74.9536 19.7214 75.4564 20.2439 76.1204 20.6122C76.7843 20.9806 77.5826 21.1641 78.5155 21.1641C79.3041 21.1641 80.0436 21.0198 80.7341 20.7299C81.4247 20.4414 82.0353 20.0394 82.5676 19.5267C83.0999 19.0141 83.5243 18.4496 83.8394 17.8305H80.8028C80.5927 18.1065 80.3321 18.3306 80.024 18.5014C79.7145 18.6723 79.3433 18.7578 78.9105 18.7578C78.4497 18.7578 78.0729 18.6597 77.7773 18.4622C77.4818 18.2647 77.2773 17.986 77.1667 17.6246C77.0798 17.3431 77.0504 17.0308 77.0798 16.6876H84.275C84.3408 16.4509 84.3997 16.1876 84.4529 15.899C84.5047 15.6105 84.5383 15.3205 84.5509 15.032C84.5902 14.1916 84.4487 13.4521 84.1265 12.8134L84.1251 12.8148ZM81.6109 14.9536H77.4356C77.5252 14.7169 77.6345 14.5026 77.7661 14.3121C78.0421 13.9115 78.367 13.6117 78.7424 13.4142C79.1164 13.2168 79.5142 13.1187 79.9358 13.1187C80.3041 13.1187 80.6193 13.1943 80.8826 13.3456C81.1459 13.4969 81.3462 13.707 81.4835 13.9759C81.6222 14.2448 81.6642 14.5712 81.6123 14.9522L81.6109 14.9536Z"
        fill="white"
      />
      <path
        d="M84.8311 20.9288L86.5665 10.9911H89.1886L89.169 12.7657C89.4967 12.3582 89.8651 12.0038 90.2727 11.7012C90.6803 11.3987 91.1299 11.1662 91.6229 11.0009C92.1159 10.837 92.6384 10.7544 93.1903 10.7544L92.6384 13.8891H91.6327C91.2517 13.8891 90.8904 13.9325 90.5486 14.0179C90.2068 14.1034 89.9043 14.242 89.6424 14.4325C89.3791 14.623 89.1564 14.8835 88.9715 15.2113C88.7866 15.5405 88.6563 15.948 88.5765 16.4341L87.7879 20.9302H84.8297L84.8311 20.9288Z"
        fill="white"
      />
      <path
        d="M92.6104 20.9288L94.3458 10.9911H96.9679L96.9483 12.7657C97.276 12.3582 97.6444 12.0038 98.052 11.7012C98.4596 11.3987 98.9092 11.1662 99.4022 11.0009C99.8952 10.837 100.418 10.7544 100.97 10.7544L100.418 13.8891H99.412C99.031 13.8891 98.6697 13.9325 98.3279 14.0179C97.9861 14.1034 97.6836 14.242 97.4217 14.4325C97.1584 14.623 96.9357 14.8835 96.7508 15.2113C96.5659 15.5405 96.4356 15.948 96.3558 16.4341L95.5672 20.9302H92.609L92.6104 20.9288Z"
        fill="white"
      />
      <path
        d="M101.034 25.2667L104.11 20.258H103.519L101.331 10.9912H104.524L105.943 17.4777L109.591 10.9912H112.745L104.148 25.2667H101.033H101.034Z"
        fill="white"
      />
    </svg>
  );
};
