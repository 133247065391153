interface IProps {
  strokeColor: string;
}

export const BurgerIcon = ({ strokeColor }: IProps) => {
  return (
    <svg
      stroke={strokeColor ?? 'currentColor'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="2"
    >
      <path
        d="M3 12H21M3 6H21M3 18H21"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
