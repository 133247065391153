/* eslint-disable  max-len */

interface IProps {
  width?: number;
  height?: number;
}

export const LogoBlack = ({ width, height }: IProps) => {
  return (
    <svg
      width={width ?? 152}
      height={height ?? 43}
      viewBox="0 0 152 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.91734 5.77612V21.0453L0 26.7715V11.5023L9.91734 5.77612Z"
        fill="#2BBD57"
      />
      <path
        d="M23.1417 28.6808L13.2244 34.407L0 26.7715L9.91734 21.0453L23.1417 28.6808Z"
        fill="#56E881"
      />
      <path
        d="M19.8359 15.3191L29.7533 9.5929L42.9777 17.2284L33.0584 22.9546L19.8359 15.3191Z"
        fill="#C93D94"
      />
      <path
        d="M33.0586 38.2237L42.9778 32.4976V17.2284L33.0586 22.9546V38.2237Z"
        fill="#A7337B"
      />
      <path
        d="M59.1374 28.9378C57.813 28.9378 56.6485 28.7121 55.6421 28.2625C54.6357 27.8129 53.8907 27.1413 53.4035 26.2496C52.9181 25.3579 52.7808 24.2763 52.9934 23.0046H57.2298C57.1245 23.5351 57.1678 23.9941 57.3615 24.3816C57.5553 24.771 57.8506 25.0701 58.2494 25.2827C58.6464 25.4953 59.1185 25.6006 59.666 25.6006C60.2134 25.6006 60.695 25.5084 61.1088 25.3222C61.5227 25.136 61.8463 24.8764 62.0758 24.5415C62.3053 24.2066 62.437 23.8003 62.4727 23.3225C62.4727 23.0046 62.4069 22.7186 62.2733 22.4609C62.1416 22.2051 61.946 21.9756 61.6901 21.7724C61.4343 21.5692 61.1295 21.3849 60.7759 21.2156C60.4222 21.0481 60.0422 20.8976 59.6377 20.766C59.2314 20.6343 58.8176 20.48 58.3924 20.3032C57.1395 19.8799 56.182 19.2873 55.5198 18.5292C54.8577 17.7711 54.5698 16.8173 54.6582 15.6698C54.694 14.4866 55.042 13.45 55.7042 12.5583C56.3664 11.6667 57.2449 10.9781 58.3397 10.4928C59.4346 10.0074 60.6705 9.76477 62.0475 9.76477C63.3004 9.76477 64.3727 9.9717 65.2644 10.3874C66.1561 10.8032 66.822 11.4202 67.2641 12.2404C67.7043 13.0606 67.8548 14.0539 67.7137 15.2202H63.4509C63.5036 14.7969 63.4547 14.4207 63.3061 14.0953C63.1556 13.7679 62.9129 13.5215 62.578 13.3541C62.2432 13.1867 61.8369 13.102 61.359 13.102C60.8812 13.102 60.4899 13.1772 60.1269 13.3277C59.7657 13.4782 59.4778 13.7077 59.2653 14.0163C59.0527 14.3248 58.9379 14.6822 58.921 15.0885C58.8853 15.3895 58.9304 15.6491 59.0527 15.8692C59.1769 16.0893 59.3386 16.285 59.5418 16.4524C59.745 16.6198 59.9971 16.7797 60.2962 16.9283C60.5953 17.0788 60.932 17.2199 61.3026 17.3516C61.6732 17.4833 62.0532 17.6206 62.4407 17.7617C63.0578 17.9912 63.6315 18.2508 64.162 18.5424C64.6925 18.834 65.159 19.182 65.5654 19.5883C65.9717 19.9947 66.284 20.4706 66.506 21.018C66.7261 21.5655 66.8107 22.1825 66.758 22.871C66.7054 24.0712 66.3517 25.1266 65.6989 26.0352C65.0462 26.9438 64.1545 27.6549 63.0239 28.1665C61.8933 28.6782 60.5972 28.9341 59.1317 28.9341L59.1374 28.9378Z"
        fill="#0C111D"
      />
      <path
        d="M68.9157 34.446L72.791 27.7264L71.7977 27.7189L68.8574 15.2729H73.1465L75.024 23.8549L79.9508 15.2729H84.1872L73.0976 34.446H68.9139H68.9157Z"
        fill="#0C111D"
      />
      <path
        d="M91.4793 28.9378C90.8435 28.9378 90.2791 28.8588 89.7843 28.6989C89.2896 28.5409 88.8663 28.3114 88.5127 28.0104C88.159 27.7113 87.8768 27.3934 87.6661 27.0566L86.9249 28.6199H83.4297L86.7933 9.55408H90.7663L89.4683 16.8362C89.9988 16.2887 90.6252 15.8391 91.3495 15.4855C92.0737 15.1318 92.9033 14.955 93.8383 14.955C94.9331 14.955 95.8813 15.224 96.6845 15.762C97.4878 16.3 98.1011 17.0299 98.5243 17.9461C98.9476 18.8641 99.1338 19.9063 99.0812 21.0707C99.0285 22.1298 98.8028 23.14 98.4058 24.1032C98.0089 25.0663 97.4652 25.9091 96.7767 26.6315C96.0882 27.3558 95.2943 27.9201 94.3933 28.3265C93.4922 28.7328 92.5215 28.936 91.4793 28.936V28.9378ZM91.1614 25.4689C91.8499 25.4689 92.4763 25.3015 93.0407 24.9666C93.605 24.6318 94.0603 24.1728 94.4045 23.5896C94.7488 23.0065 94.9388 22.3367 94.9745 21.5767C95.0272 20.9936 94.9426 20.4612 94.7225 19.974C94.5024 19.4886 94.175 19.1086 93.7424 18.8359C93.3097 18.5631 92.7924 18.4258 92.1923 18.4258C91.4868 18.4258 90.8547 18.5988 90.2998 18.9431C89.7448 19.2873 89.2896 19.7558 88.9359 20.3465C88.5823 20.939 88.3979 21.6144 88.3791 22.3725C88.3433 22.9914 88.4355 23.5332 88.6575 24.0016C88.8776 24.47 89.2012 24.8312 89.6244 25.087C90.0477 25.3429 90.5594 25.4708 91.1614 25.4708V25.4689Z"
        fill="#0C111D"
      />
      <path
        d="M112.986 17.7222C112.554 16.8662 111.905 16.1909 111.039 15.6961C110.174 15.2014 109.134 14.955 107.915 14.955C106.696 14.955 105.62 15.1619 104.63 15.5776C103.641 15.9934 102.781 16.5671 102.049 17.2989C101.316 18.0326 100.738 18.8791 100.315 19.8404C99.8915 20.8036 99.6526 21.8401 99.5999 22.9519C99.5473 24.1352 99.7504 25.1811 100.209 26.0897C100.668 26.9983 101.344 27.7 102.235 28.1948C103.127 28.6895 104.199 28.9359 105.452 28.9359C106.511 28.9359 107.505 28.7422 108.432 28.3528C109.36 27.9653 110.18 27.4254 110.895 26.7368C111.609 26.0483 112.179 25.2902 112.603 24.4587H108.524C108.242 24.8293 107.892 25.1303 107.478 25.3598C107.063 25.5893 106.564 25.7041 105.983 25.7041C105.364 25.7041 104.858 25.5724 104.461 25.3071C104.064 25.0419 103.789 24.6675 103.641 24.1822C103.524 23.8041 103.485 23.3846 103.524 22.9237H113.188C113.276 22.6057 113.355 22.2521 113.427 21.8646C113.496 21.477 113.541 21.0876 113.558 20.7001C113.611 19.5714 113.421 18.5781 112.988 17.7203L112.986 17.7222ZM109.61 20.5948H104.002C104.122 20.2768 104.269 19.989 104.446 19.7332C104.816 19.1952 105.253 18.7926 105.757 18.5273C106.259 18.2621 106.794 18.1304 107.36 18.1304C107.855 18.1304 108.278 18.232 108.632 18.4352C108.985 18.6383 109.254 18.9205 109.439 19.2817C109.625 19.6429 109.681 20.0812 109.612 20.5929L109.61 20.5948Z"
        fill="#0C111D"
      />
      <path
        d="M113.935 28.6199L116.266 15.2729H119.788L119.762 17.6563C120.202 17.1089 120.696 16.633 121.244 16.2266C121.791 15.8203 122.395 15.508 123.057 15.286C123.72 15.0659 124.421 14.955 125.162 14.955L124.421 19.1651H123.071C122.559 19.1651 122.073 19.2234 121.614 19.3381C121.155 19.4529 120.749 19.6391 120.397 19.895C120.044 20.1508 119.745 20.5007 119.496 20.9409C119.248 21.383 119.073 21.9304 118.966 22.5832L117.907 28.6218H113.934L113.935 28.6199Z"
        fill="#0C111D"
      />
      <path
        d="M124.383 28.6199L126.714 15.2729H130.235L130.209 17.6563C130.649 17.1089 131.144 16.633 131.691 16.2266C132.239 15.8203 132.842 15.508 133.505 15.286C134.167 15.0659 134.868 14.955 135.61 14.955L134.868 19.1651H133.518C133.006 19.1651 132.521 19.2234 132.062 19.3381C131.603 19.4529 131.196 19.6391 130.845 19.895C130.491 20.1508 130.192 20.5007 129.944 20.9409C129.695 21.383 129.52 21.9304 129.413 22.5832L128.354 28.6218H124.381L124.383 28.6199Z"
        fill="#0C111D"
      />
      <path
        d="M135.697 34.446L139.828 27.7189H139.034L136.096 15.2729H140.385L142.291 23.9847L147.189 15.2729H151.426L139.879 34.446H135.695H135.697Z"
        fill="#0C111D"
      />
    </svg>
  );
};
