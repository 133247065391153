export const Navigation = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99967 10.4166C9.38039 10.4166 10.4997 9.29734 10.4997 7.91663C10.4997
        6.53591 9.38039 5.41663 7.99967 5.41663C6.61896 5.41663 5.49967 6.53591
        5.49967 7.91663C5.49967 9.29734 6.61896 10.4166 7.99967 10.4166Z"
        stroke="#2BBD57"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99967 18.3333C9.66634 15 14.6663 12.8485 14.6663 8.33329C14.6663
        4.65139 11.6816 1.66663 7.99967 1.66663C4.31778 1.66663 1.33301 4.65139
        1.33301 8.33329C1.33301 12.8485 6.33301 15 7.99967 18.3333Z"
        stroke="#2BBD57"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
