/* eslint-disable indent */
export const CrossIcon = ({
  variant,
  onClick,
  className,
}: {
  variant?: string;
  onClick?: () => void;
  className?: string;
}) => {
  const renderCross = () => {
    switch (variant) {
      case 'medium': {
        return (
          <svg
            onClick={onClick}
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
          >
            <path
              d="M22.9922 13L12.9922 23M12.9922 13L22.9922 23"
              stroke="#98A2B3"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      default: {
        return (
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 1L1 13M1 1L13 13"
              stroke="#344054"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
    }
  };

  return renderCross();
};
