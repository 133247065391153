import { RefObject, useEffect, useMemo, useState } from 'react';

export const useIsInViewport = (ref: RefObject<Element>) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(() => {
    if (typeof IntersectionObserver !== 'undefined') {
      return new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting));
    }

    return null;
  }, []);

  useEffect(() => {
    if (ref.current && observer) {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [ref, observer]);

  return isIntersecting;
};
