/* eslint-disable  max-len */

interface IProps {
  classname?: string;
}

export const LinkedIn = ({ classname }: IProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
    >
      <path d="M23.7609 7.6998C23.7609 7.6998 23.5266 6.04512 22.8047 5.31855C21.8906 4.3623 20.8688 4.35762 20.4 4.30137C17.0438 4.05762 12.0047 4.05762 12.0047 4.05762H11.9953C11.9953 4.05762 6.95625 4.05762 3.6 4.30137C3.13125 4.35762 2.10938 4.3623 1.19531 5.31855C0.473438 6.04512 0.24375 7.6998 0.24375 7.6998C0.24375 7.6998 0 9.64512 0 11.5857V13.4045C0 15.3451 0.239062 17.2904 0.239062 17.2904C0.239062 17.2904 0.473437 18.9451 1.19062 19.6717C2.10469 20.6279 3.30469 20.5951 3.83906 20.6982C5.76094 20.8811 12 20.9373 12 20.9373C12 20.9373 17.0438 20.9279 20.4 20.6889C20.8688 20.6326 21.8906 20.6279 22.8047 19.6717C23.5266 18.9451 23.7609 17.2904 23.7609 17.2904C23.7609 17.2904 24 15.3498 24 13.4045V11.5857C24 9.64512 23.7609 7.6998 23.7609 7.6998ZM9.52031 15.6123V8.86699L16.0031 12.2514L9.52031 15.6123Z" />
    </svg>
  );
};
